/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item {
  display: block;
  float: left;
}
.menu {
  display: block;
  color: #999;
}
.menu:hover,
.menu:focus,
a.path {
  color: #222;
}
div.sub1 > .item {
  margin-right: 20px;
  position: relative;
}
div.sub1 > .exit {
  margin-right: 0;
}
div.sub1 .menu,
div.sub1 .menu:visited {
  color: #939598;
  font-size: 14px;
  text-transform: uppercase;
}
div.sub1 a.path {
  color: #000 !important;
}
div.sub1 .menu:hover,
div.sub1 .menu:focus {
  color: #000;
}
div.sub2 {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 100;
  width: auto;
  padding-top: 6px;
}
div.sub2 > .item {
  width: 100%;
}
div.sub2 .menu,
div.sub2 .menu:visited {
  white-space: nowrap;
  display: block;
  width: 100%;
  color: #6d6e71;
  text-transform: none;
  padding: 0 6px;
  background: url(/images/bg-sub2.png) repeat 0 0;
  border: 1px solid #e6e7e8;
}
div.sub2 a.path {
  color: #f1f2f2 !important;
  background: #6d6e71 !important;
}
div.sub2 .menu:hover,
div.sub2 .menu:focus {
  color: #f1f2f2;
  background: #6d6e71;
}
.dock {
  display: block;
  float: left;
}
.meta,
.meta:visited {
  color: green;
}
/******* layout-medium.less 2013-1-16 *******/
#head {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
  margin-top: 32px;
}
.cb-layout2 #head,
.cb-layout3 #head {
  margin-left: 1%;
  margin-right: 1%;
  width: 98%;
}
div.sub1 {
  float: left;
}
#footer {
  margin-top: 40px;
}
#content {
  margin-top: 20px;
}
.cb-layout2 div.main div.head {
  margin-top: 5px;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.area .part,
.area > .grid table {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.area .tiny {
  width: 46%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 4%;
  margin-left: 4%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 92%;
}
.area > .slim .tiny {
  width: 92%;
}
.cb-layout2 .area {
  width: 100%;
}
.cb-layout2 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .area h2,
.cb-layout2 .area .foot {
  margin-right: 1%;
  margin-left: 1%;
}
.cb-layout2 .area .part,
.cb-layout2 .area > .grid table {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.cb-layout2 .area .tiny {
  width: 48%;
}
.cb-layout2 .area > .slim {
  width: 50%;
}
.cb-layout2 .area > .slim h2,
.cb-layout2 .area > .slim .foot,
.cb-layout2 .area > .slim .part,
.cb-layout2 .area > .slim.grid table {
  margin-right: 2%;
  margin-left: 2%;
}
.cb-layout2 .area > .slim .part,
.cb-layout2 .area > .slim.grid table {
  width: 96%;
}
.cb-layout2 .area > .slim .tiny {
  width: 96%;
}
.cb-layout3 .area {
  width: 100%;
}
.cb-layout3 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .area h2,
.cb-layout3 .area .foot {
  margin-right: 1%;
  margin-left: 1%;
}
.cb-layout3 .area .part,
.cb-layout3 .area > .grid table {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.cb-layout3 .area .tiny {
  width: 48%;
}
.cb-layout3 .area > .slim {
  width: 50%;
}
.cb-layout3 .area > .slim h2,
.cb-layout3 .area > .slim .foot,
.cb-layout3 .area > .slim .part,
.cb-layout3 .area > .slim.grid table {
  margin-right: 2%;
  margin-left: 2%;
}
.cb-layout3 .area > .slim .part,
.cb-layout3 .area > .slim.grid table {
  width: 96%;
}
.cb-layout3 .area > .slim .tiny {
  width: 96%;
}
.wide.form .tile .name,
.wide a.capt {
  float: left;
  width: 33%;
}
.wide.form .tile .chop,
.wide.form .tile textarea,
.wide.form .tile input.text,
.wide.form .tile .ctrl,
.wide input.capt {
  float: right;
  width: 63%;
}
.wide.resp .swap > table {
  float: left;
  width: 70% !important;
}
.wide.resp > .foot > .scan > .ctrl {
  margin-top: 0;
}
.wide p.topi {
  float: left;
  width: 60%;
}
.wide .resp > .link {
  text-align: right;
}
.wide .resp > .link > .open {
  float: left;
  width: 60%;
  text-align: left;
}
.wide .tall.auth.tile .text,
.wide .tall.auth.tile .password {
  width: 63%;
}
.wide .tall.payp.tile,
.wide .tall.payp.tile .papc {
  position: static;
}
.wide .tall.payp.tile .papc {
  margin: 0;
  padding: 0;
}
.wide .tall.payp.tile input.numb {
  position: static;
  float: left;
}
.wide .tall.payp.tile .name {
  float: left;
  width: 60%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
#header {
  position: relative;
  z-index: 10;
}
.cb-layout2 div.main div.wide {
  width: 50%;
}
.cb-layout2 div.main div.slim div.part {
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
}
.cb-layout3 div.main div.unit .part,
.cb-layout3 div.main div.unit .grid table {
  margin-top: 2%;
  margin-bottom: 2%;
}
@media (max-width: 1199px) {
  .cb-layout4 div.main div.unit.wide {
    width: 50%;
  }
  .cb-layout4 div.main div.unit.wide div.head h2,
  .cb-layout4 div.main div.unit.wide div.part {
    margin-left: 4%;
    margin-right: 4%;
  }
  .cb-layout4 div.main div.unit.wide div.part {
    width: 92%;
  }
}
@media (max-width: 1239px) {
  div.unit.cb-matrix-columns-3 div.part {
    width: 31.333% !important;
  }
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 10% !important;
}
#disp.zoom,
#disp.tube {
  left: 0%;
  margin-left: 0;
  width: 100%;
}
#disp a.capt {
  width: 50%;
}
#disp input.capt {
  float: right;
  width: 48%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
#disp.cb-photoeditor {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
#disp.cb-lightbox-code {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
#disp.shop,
#disp.cb-shoppingcart {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/******* module-shop-medium.less 2013-1-16 *******/
.cb-shop-tabs > li {
  display: inline-block;
  margin-bottom: -1px;
}
.cb-shop-tab {
  margin-right: 0.3em;
  border-bottom-width: 1px;
  border-radius: 4px 4px 0 0;
}
.cb-shop-tab.selected {
  border-bottom-color: #f5f5f5;
}
#disp.shop .name,
a.agbs,
.cb-shop-country > label {
  width: 37%;
}
#disp.shop .file,
#disp.shop .text,
#disp.shop select {
  width: 62%;
}
#disp.shop .zip {
  clear: none;
  width: 10%;
}
#disp.shop input.city {
  width: 51%;
}
#disp.shop .cb-shop-currency > select {
  width: 55%;
}
.cb-shop-currency > select + a {
  width: 6%;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a {
  clear: none;
  margin-bottom: 0.5em;
}
#disp .cb-shop-country > select {
  width: 62%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart .ship,
.cb-shoppingcart fieldset.tiny {
  float: left;
  width: 49%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart fieldset.tiny {
  margin-right: 2%;
}
/*# sourceMappingURL=./screen-medium.css.map */